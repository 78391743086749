import React from "react"
import { Link, graphql } from "gatsby"
import get from "lodash.get"

import getExcerptOfBody from "../utils/getExcerpt"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Grid from "../components/grid"
import Breadcrumbs from "../components/breadcrumbs"
import PostBody from "../components/post-body"
import Share from "../components/share"

export const query = graphql`
  query FilmEntryBySlug($uid: String!) {
    prismicFilm(uid: { eq: $uid }) {
      id
      uid
      type
      data {
        image {
          gatsbyImageData(layout: CONSTRAINED, width: 1032)
          alt
          copyright
        }
        title
        date
        body {
          ... on PrismicFilmDataBodyText {
            id
            slice_type
            slice_label
            primary {
              text {
                text
                richText
              }
            }
          }
          ... on PrismicFilmDataBodyQuote {
            id
            slice_type
            slice_label
            primary {
              quote {
                text
                richText
              }
              author {
                text
                richText
              }
            }
          }
          ... on PrismicFilmDataBodyImage {
            id
            slice_type
            slice_label
            primary {
              caption {
                text
                richText
              }
              image {
                alt
                copyright
                gatsbyImageData(layout: CONSTRAINED, width: 856)
              }
            }
          }
          ... on PrismicFilmDataBodyGallery {
            id
            slice_type
            slice_label
            items {
              caption {
                text
                richText
              }
              image {
                alt
                copyright
                gatsbyImageData(layout: CONSTRAINED, width: 1024)
              }
            }
          }
          ... on PrismicFilmDataBodyMovie {
            id
            slice_type
            slice_label
            items {
              headline
              text
            }
            primary {
              poster {
                alt
                copyright
                gatsbyImageData(layout: CONSTRAINED, width: 1024)
              }
            }
          }
        }
      }
    }
  }
`

export default function Film({ data, location }) {
  const film = get(data, `prismicFilm`)
  if (!film) return null

  return (
    <Layout location={location}>
      <SEO
        title={film.data.title}
        description={getExcerptOfBody(film.data.body)}
        image={film.data.image}
      />

      <Grid>
        <Breadcrumbs>
          <Link to="/">Home</Link>
          <Link to="/films">Films</Link>
          <span>{film.data.title}</span>
        </Breadcrumbs>
        <PostBody post={film} />
        <Share
          location={location}
          image={film.data.cover}
          description={getExcerptOfBody(film.data.body)}
        />
      </Grid>
    </Layout>
  )
}
